.preloader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background-color: $color-bg;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	color: $color-white;
	&__inner{
		display: flex;
		width: min-content;
		margin: auto;
	}
	&__status{

		.logo{
			width: rem(180);
			line-height: 1;
			font-size: 0;
			margin-bottom: rem(25);
			figure{
				margin: 0;
			}
		}
		.statusBar{
			display: block;
			width: 100%;
			position: relative;
			height: 3px;
			background-color: rgba(black,.15);
			border-radius: 10px;
			overflow: hidden;
			&__inner{
				background-color: $color-primary;
				border-radius: 10px;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				animation: statusBar .8s cubic-bezier(0.8, 0.01, 0.59, 1) .2s forwards;
			}
		}
	}
	&__powered{
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 2em;
		font-size: 1rem;

		font-weight: 400;
		text-align: center;
	}
}


@keyframes statusBar {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}
