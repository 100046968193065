#--serviceInfo .info-tool{
    position: fixed;
    top: 2vmin;
    right: 2vmin;
    width: 12em;
    min-height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5em;
    opacity: 0;
    transform: translateY(.6em);
    z-index: -1;
    color: var(--color-text);
    pointer-events: none;
    font-size: 1.5rem;
    border-radius: 10px;
    filter: blur(10px);
    p{
        z-index: 1;
        position: relative;
    }
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }
    @include r(767){
        width: calc(100% - 4vmin);
    }
    &_success::before{
        background: green;
    }
    &_error::before{
        background: red;
    }
    &.start-animation{
        z-index: 999;
        transition: all $transition-base;
        &.is-animating{
            filter: blur(0);
            opacity: 1;
            transform: none;
        }
    }
}
#--serviceIcons,
#--serviceSpriteIcons{
    button{
        @extend %reset-btn;
    }
    .-service-icons{
        border: 1px solid var(--color-border);
        border-radius: 5px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        padding: 1em 0.5em 0.5em;
        cursor: pointer;
        text-align: center;
        position: relative;
        user-select: none;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: .4s ease;
        }
        &:not(.active){
            pointer-events: none;
            filter: grayscale(100%) opacity(.4);
        }
        &:hover{
            &::after{
                box-shadow: 0px 5px 25px alpha(var(--color-bg-invert), 0.2);
            }
        }
        .img-wrap{
            margin: 0;
            max-width: 60%;
            flex: 1 1 100%;
            width: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img{
            margin: 0;

        }
        .text-wrap{
            display: flex;
            overflow: hidden;
            user-select: none;
            gap: var(--gap);
            mask-image: linear-gradient(
              var(--mask-direction, to right),
              hsl(0 0% 0% / 0),
              hsl(0 0% 0% / 1) 20%,
              hsl(0 0% 0% / 1) 80%,
              hsl(0 0% 0% / 0)
            );
            position: static;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transition: .4s ease;
            }
        }
        .text{
            --size: 50px;
            --gap: calc(var(--size) / 3);
            --scroll-start: 0;
            --scroll-end: calc(-90% - var(--gap));
            --duration: 25s;
            font-size: 80%;
            line-height: 1.3;
            height: 2em;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: var(--gap);
            min-width: 100%;
            animation: scroll-x var(--duration) var(--anim-delay, 0) linear infinite;

            span{
                white-space: nowrap;
            }

        }
    }
}


@keyframes scroll-x {
    from {
      transform: translateX(var(--scroll-start));
    }
    to {
      transform: translateX(var(--scroll-end));
    }
}

#--serviceFilterBtns,
#--servicesSearch{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    .btn:not(.active){
        opacity: .4;
    }
    .btn{
        font-size: 70%;
        text-transform: none;
        min-height: auto;
        min-width: fit-content;
        span{
            text-transform: none;
        }
        span{
            pointer-events: none;
        }
    }
}
