.setup-page{
	background: $color-bg;
	color: $color-white;

}
.setup{
	background-color: darken($color-bg,10%);
	position: relative;
	overflow: hidden;
	width: 100%;
	&__videos{
		position: fixed;
		overflow: hidden;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 100;
		pointer-events: none;
	}
	&__wrapper{
		position: relative;
		width: 100%;
		height: calc(100vh - 80px);
	}
	&__top{
		display: flex;
		width: 100%;
		// height: 37px;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		background-color: darken($color-bg,10%);
		z-index: 4;
	}
	&__sidebar{
		display: none;
		padding-top: 47px;
		width: 32px;
		height: 100%;
		justify-content: space-between;
		flex-direction: column;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $color-bg;
		z-index: 3;
	}
	&__main{
		@extend %overlay;
		padding-top: 44px;
	}
}
.request-demo{
	position: fixed;
	overflow: hidden;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	pointer-events: none;
	color: $color-bg;
	&__container{
		max-height: 100vh;
		position: relative;
		overflow-y: auto;
		padding-bottom: 1vmin;
		scrollbar-width: thin;
		scrollbar-color: var(--color-primary) #e2e2e2;
		@include r(1023){
			max-width: 100%;
			padding: 1rem;
			.form__wrapper{
				padding-top: 6rem;
				padding-bottom: 6rem;
			}
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: #e2e2e2;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--color-primary);
			border-radius: 20px;
		}
	}
}

.setup-videos,
.request-demo{
	&__overlay{
		display: none;
		@extend %overlay;
		background-color: rgba($color-bg,.2);
		backdrop-filter: blur(2px);
		pointer-events: auto;
	}
	&__container{
		pointer-events: auto;
		top: 0;
		right: 0;
		position: absolute;
		width: 480px;
		height: 100%;
		padding: 40px 20px 0 30px;
		background-color: white;
		transform: translateX(100%);
		transition: transform .34s ease-in;
	}
	&.is-open{
		.setup-videos__container,
		.request-demo__container{
			transform: translateX(0);
		}
		.setup-videos__overlay,
		.request-demo__overlay{
			display: block;
		}
	}
	&__close{
		@extend %reset-btn;
		position: absolute;
		width: 40px;
		height: 40px;
		top: 20px;
		right: 20px;
		color: $color-bg;
		svg{
			stroke: currentColor;
		}
	}
	&__title{
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 36px;
		text-transform: uppercase;
		color: $color-bg;
	}
	&__list{
		display: block;
		overflow-y: auto;
		height: calc(100vh - 76px);
		overflow: scroll;
		scroll-behavior: smooth;
		padding-right: 15px;
		overscroll-behavior: contain;
		@supports (scrollbar-gutter: stable) {
			overflow: auto;
			scrollbar-gutter: stable;
		}

		& {
			scrollbar-width: thin;
			scrollbar-color: var(--color-text) var(--color-border);
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: var(--color-border);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--color-text);
			border-radius: 12px;
		}
	}
}

.btn-setup{
	@include header-btn($color-white,$color-primary-200);
	margin: 1em auto 1em 0;
    width: fit-content;
}

.request-demo{
	&__container{
		right: auto;
		left: 0;
		transform: translateX(-100%)
	}
	.is-open-requestDemo &{
		.request-demo__container{
			transform: translateX(0);
		}
		.request-demo__overlay{
			display: block;
		}
	}
	.form__title{
		h2{
			font-family: $title-font;
			font-style: normal;
			line-height: 1;
			margin-top: 0;
			font-size: 3.5rem;
			color: #455a64;
			font-weight: 700;
		}
		.light{
			font-weight: 300;
		}
	}
}
body.is-open-requestDemo{
	overflow: hidden
}



.setup-main{
	&__bg{
		@extend %overlay;
		width: 100%;
		object-fit: cover;
	}
}


.subscribeModal{
	--form-label-bg: transparent;
	@include r(767){
		&__container{
			padding: 1rem;
			width: 100%;
			right: 0;
			bottom: 0;
			border-radius: 0;
		}
		.form{
			padding-bottom: 1rem;
		}
		.request-button{
			filter: drop-shadow(2px 4px 6px rgba(0,0,0,.3));
			min-width: 20rem;
			margin: 0 auto;
			.text{
				flex: 1;
			}
		}
	}
	&__overlay{
		display: none;
		@extend %overlay;
		position: fixed;
		background-color: rgba($color-bg,.2);
		backdrop-filter: blur(2px);
		pointer-events: none;
		z-index: 100;
	}
	&__container{
		padding: 2rem;
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		background: url('/img/decor/relief-map.webp'), linear-gradient(cAlc(var(--deg,0) * 1deg), $color-primary-200 1.13%, $color-primary 100%);
		box-shadow: 0px 40px 60px rgba(69, 90, 100, .25);
		background-size: 100% 100%, auto;
		border-radius: 20px;
		color: $color-white;
		pointer-events: none;
		transform: translateY(10%);
		opacity: 0;
		transition: transform .24s ease-in, opacity .34s ease-in;
		z-index: 101;
	}
	&__close{
		@extend %reset-btn;
		position: absolute;
		color: $color-white;
		background-color: $color-primary-200;
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 1rem;
		right: 1rem;
		svg{
			stroke: currentColor;
			max-width: 65%;
		}
	}
	&__title{
		font-size: 1.3rem;
		padding-right: 2rem;
	}
	.form{
		display: flex;
		width: 34rem;
		.form_js-validate{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		&__wrapper{
			padding: 1rem 0 0;
		}
		.form__line{
			&:nth-child(1),
			&:nth-child(2){
				flex: 0 1 48%;
			}
		}
		.form__field, .form__line>.form__line{
			font-size: 1.2rem;
		}
		&__input{
			clip-path: polygon(0px 0px, 0.09% 22.92%, var(--labelWidth, 28.5%) 25.54%, var(--labelWidth, 28.5%) -4.17%, 102.34% -4px, 102.79% 113.5%, -1.89% 111.42%);
		}
	}
	.modal-newsletter-is-active &{

		.subscribeModal__container{
			transform: translateY(0);
			opacity: 1;
			pointer-events: auto;
		}
		.subscribeModal__overlay{
			display: block;
			pointer-events: auto;
		}

	}
}


.setup-video{
	margin: 30px 0;
	&__main{
		position: relative;
		aspect-ratio: 16/9;
		overflow: hidden;
		border-radius: 7px;
	}
	&__frame{
		@extend %overlay;
		max-width: 100%;
		height: 100%;
		z-index: 1;
	}
	&__poster{
		@extend %overlay;
		z-index: 2;
	}
	&__icon{
		@extend %reset-btn;

		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		border-radius: 50%;
		background-color: $color-white;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		.svg-icon{
			width: 15px;
			height: 15px;
		}
	}
	&__title{
		font-weight: 500;
		font-size: 18px;
		line-height: 1.67;
		color: $color-bg;
	}
}

%icon{
	border-radius: 5px;
	border: 2px solid rgba($color-primary, .4);
	transition: border-color .23s;
	&:hover{
		border-color: $color-primary;
	}
}


.setup-top__icon{
	width: 30px;
	height: 30px;
	display: flex;
    justify-content: center;
	.svg-icon{
		max-width: 80%;
		width: 100%;

	}
	&.bigger .svg-icon{
		max-width: 100%;
	}
	&.js-tooltipster{
		@extend %icon;
	}
}



.setup-main__test{
	padding: 20px;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: darken($color-bg,10%);
	display: grid;
	grid-template-columns: repeat(20, 40px);
	grid-template-rows: repeat(2, 40px);
	top: 44px;
	left: 35px;
	gap: 15px;
	max-width: calc(100% - 35px);
	align-content: center;
	z-index: 100;
	.setup-test-icon{
		width: 40px;
		height: 40px;
	}
}


.setup-top{
	padding: 5px 10px;
	&__logo{
		display: flex;
		align-items: center;
		.logo-mini{
			width: 14px;
			margin-right: 20px;
		}
		.text{
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: .05em;
		}
	}
	&__icons{
		display: flex;
		.setup-top__icon{
			+ .setup-top__icon{
				margin-left: .5em;
			}
		}
	}
	&__separator{
		width: 30px;
		height: 30px;
		position: relative;
		&::after{
			content: '';
			position: absolute;
			width: 1px;
			height: 80%;
			left: 50%;
			top: 10%;
			background-color: rgba(#fff, .1);
		}
	}
	&__services{
		display: flex;
	}
	&__select{
		// @extend %icon;
		margin-right: .5em;
		width: 130px;
		height: 34px;
		overflow: hidden;
		padding: 0px 3px;
		.top{
			font-size: 9px;
		}
		.bottom{
			line-height: 1.2;
			font-size: 12px;
			display: block;
			width: 100%;
			border-bottom: 1px solid white;
			position: relative;
			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border: 4px solid transparent;
				right: 0;
				top: 0;
				border-top-color: white;
			}
		}
	}
	&__menu{
		// @extend %icon;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2px 9px;
		font-weight: 500;
		font-size: 10px;
		letter-spacing: 0.1em;
		.icon{
			width: 1.5em;
			height: 1.4em;
			display: flex;
			margin-left: 1em;
			position: relative;
			background: repeating-linear-gradient(to bottom, transparent 2px, white 3px, transparent 5px);
		}
	}
}
.opacity-05{
	opacity: .5;
}


.setup-sidebar{
	&__icons{
		.setup-top__icon{
			& + .setup-top__icon{
				margin-top: .2em;
			}
		}
	}
	&__separator{
		width: 30px;
		height: 30px;
		position: relative;
		&::after{
			content: '';
			position: absolute;
			width: 80%;
			height: 1px;
			left: 10%;
			top: 42%;
			background-color: rgba(#fff, .1);
		}
	}
	&__logo{
		transform: rotate(-90deg);
		transform-origin: 15% 8%;
		letter-spacing: .14em;
	}
}

.tooltipster-content{
	.title{
		font-size: 120%;
	margin-bottom: 0.5em;
	font-weight: 500;
	}
	.text{

	}
	.link{
		margin-top: 0.75em;
		color: $color-primary-200;
		text-decoration: underline;
		display: inline-block;
		transition: .23s;
		&:hover{
			text-decoration: none;
			color: $color-primary;
		}
	}
}


.setup-page_setup{
	.setup-sidebar{
		display: flex;
	}
	.setup-main{
		&__bg{
			top: 44px;
			left: 32px;
			width: calc(100% - 32px);
			height: calc(100% - 44px);
			background-color: #b0a297;
			.center{
				background-image: url('#{$pathPrefix}img/bg/setup-top.jpg');
				background-size: contain;
				z-index: 4;
				top: 50%;
				left: 50%;
				width: 35vw;
				position: absolute;
				aspect-ratio: 774/390;
				transform: translate(-50%,-50%);
			}
			.right{
				background-image: url('#{$pathPrefix}img/bg/Setup-right.jpg');
				background-size: contain;
				z-index: 3;
				bottom: 0;
				right: 0;
				height: 100%;
				position: absolute;
				aspect-ratio: 254/935;
				// width: auto;
			}
		}
	}
}

.setup-page_post{
	.setup-sidebar{
		display: flex;
	}
	.setup-main{
		&__bg{
			top: 44px;
			left: 0;
			width: 100%;
			height: calc(100% - 44px);
			background: top left / cover no-repeat url('#{$pathPrefix}img/bg/postprocessing-bg.jpg');
			// postprocessing-bg
			.center{
				position: absolute;
				background-image: url('#{$pathPrefix}img/bg/postprocessing-center.webp');
				background-size: contain;
				z-index: 4;
				width: 32vw;
				top: 50%;
				left: 37%;
				aspect-ratio: 790/398;
				transform: translate(-50%,-50%);
			}
			.right{
				background-image: url('#{$pathPrefix}img/bg/postprocessing-right.webp');
				background-size: contain;
				z-index: 3;
				bottom: 0;
				right: 0;
				height: 100%;
				position: absolute;
				aspect-ratio: 544/927;
				// width: auto;
			}
		}
	}
}

.setup-page_pre{
	.setup-sidebar{
		display: flex;
	}
	.setup-main{
		&__bg{
			top: 44px;
			left: 0;
			width: 100%;
			height: calc(100% - 44px);
			background-color: #878787;
			// background: top left / cover no-repeat url('#{$pathPrefix}img/bg/postprocessing-bg.jpg');
			// postprocessing-bg
			.bottom{
				position: absolute;
				background-image: url('#{$pathPrefix}img/bg/preprocessing-bottom.webp');
				background-size: contain;
				z-index: 6;
				width: 100%;
				bottom: 0%;
				left: 0%;
				aspect-ratio: 1920/100;
			}
			.left{
				width: calc(calc(485/1920) * 100%);
				background-color: #efefef;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				z-index: 1;
				&::after{
					content: '';
					position: absolute;
					background-image: url('#{$pathPrefix}img/bg/preprocessing-left.webp');
					background-size: contain;
					z-index: 3;
					left: 56%;
					top: 44%;
					width: calc(calc(360/482) * 100%);
					aspect-ratio: 364/620;
					transform: translate(-50%,-50%);
				}

			}
			.center{
				position: absolute;
				background-image: url('#{$pathPrefix}img/bg/preprocessing-center.webp');
				background-size: contain;
				z-index: 4;
				width: 43vw;
				max-width: 65vh;
				top: 43%;
				left: 58%;
				aspect-ratio: 885/817;
				transform: translate(-50%,-50%);
			}
			.right{
				background-image: url('#{$pathPrefix}img/bg/preprocessing-right.webp');
				background-size: contain;
				z-index: 3;
				bottom: 0;
				right: 0;
				height: 100%;
				position: absolute;
				aspect-ratio: 176/838;
				// width: auto;
			}
		}
	}
}




/*MAGNIFIC*/

.mfp-fade{
	&.mfp-bg {
		opacity: 0;
		transition: all .25s ease-out;
		/* overlay animate in */
		&.mfp-ready {
			opacity: 0.6;
		}
		/* overlay animate out */
		&.mfp-removing {
			opacity: 0;
		}
	}
	&.mfp-wrap{
		/* content at start */
		.mfp-content {
			opacity: 0;
			transform: scale(.6) translateY(-50%);
			transition: opacity .25s ease-out, transform .6s ease;
		}
		/* content animate it */
		&.mfp-ready{
			.mfp-content {
				opacity: 1;
				transform: scale(1) translateY(0);
  			}
		}
		/* content animate out */
		&.mfp-removing{
			.mfp-content {
				opacity: 0;
				transform: scale(.9);
  			}
		}
	}
}



/*simple modals*/

body{
	&.modal-visible{
		overflow: hidden;
	}
}
.simpleModal{
	&__overlay{
		display: none;
		@extend %overlay;
		position: fixed;
		background-color: rgba($color-bg,.2);
		backdrop-filter: blur(2px);
		pointer-events: none;
		z-index: 100;
	}
	&__container{
		padding: 2rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-40%);
		background-color: var(--color-bg);
		box-shadow: 0px 40px 60px rgba(69, 90, 100, .25);
		border-radius: 20px;
		color: var(--color-text);
		pointer-events: none;
		// transform: translateY(10%);
		opacity: 0;
		transition: transform .24s ease-in, opacity .34s ease-in;
		z-index: 101;
		&_auto{
			width: mIn(90%, 600px);
			.simpleModal__btn-wrapper{
				.simpleModal__btn{
					width: 20rem;
					max-width: 100%;
					margin-bottom: 1rem;
					.text{
						flex: 1
					}
				}
			}
		}
	}
	&__close{
		@extend %reset-btn;
		position: absolute;
		color: var(--color-text);
		background-color: var(--color-bg);
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 1rem;
		right: 1rem;
		svg{
			stroke: currentColor;
			max-width: 65%;
		}
	}
	&__title{
		font-size: 1.3rem;
		padding-right: 2rem;
	}
	.modal-visible &{
		.simpleModal__overlay{
			display: block;
			pointer-events: auto;
		}
	}
	.modal-visible_try &{
		.simpleModal__container.simpleModal__container_try{
			transform: translate(-50%,-50%);
			opacity: 1;
			pointer-events: auto;
		}
	}
	.modal-visible_auto &{
		.simpleModal__container.simpleModal__container_auto{
			transform: translate(-50%,-50%);
			opacity: 1;
			pointer-events: auto;
		}
	}
	&__btn{
		@include header-btn($color-white,$color-primary-200);
		margin-left: auto;
		margin-right: auto;
		max-width: max-content;
    	text-transform: capitalize;
	}
}



.article__thumbnail.article__thumbnail_fix{
	overflow: hidden;
    border-radius: 0 0 var(--decor-radius) var(--decor-radius);
    display: block;
    font-size: 0;
    margin-top: 3rem;
	img{
		aspect-ratio: 97/48;
		object-fit: cover;
		width: 100%;
		object-position: 50% 20%;
	}

}
@media (max-width:1023px) and (orientation: portrait){
	.article__thumbnail{
		margin-top: 1.5rem;
	}
	.article__thumbnail img{
		aspect-ratio: 100/90;
	}
}

.article__author.article__author_fix{
	display: inline-flex;
    align-items: center;
	.article__author-pic{
		font-size: 0;
		width: 3rem;
		height: 3rem;
		margin-left: 1rem;
		min-width: 50px;
		min-height: 50px;
		img{
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
			object-position: 50% 10%;
		}
	}
}
