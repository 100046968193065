// Font
$main-font: 'General Sans',
sans-serif;
$title-font: 'Cera Round Pro',
sans-serif;
$pathPrefix: '/';

:root{
	--fz: 14px;
	--color-primary: #8DC3C8;
	--color-error: #c88d8d;
	@include r(1199){
		@include css-lock(9,14,450,1199,'--fz')
	}
	@include r(1023){
		--ml-gap: 1.5rem;
		--ml-inner-gap: 1.5rem;
	}
}

$main-font-size: 14px;
$main-font-weight: 300;
$main-line-height: 1.65;

$menuMob: 768;


// Colors
$color-white: #FFFFFF;
$color-primary: #8DC3C8;
$color-primary-200: #6898AF;
$color-primary-400: #44697B;
$color-bg: #455A64;
$color-grey: #D6D6D6;


// Z-indexes
$z-indexes: (
	"header",
	"footer",
	"modal",
);
// use:
// .header{
// 	z-index: z('header');
// }



// Transitions
$transition-base: 0.33s cubic-bezier(.23, 1, .32, 1);
$transition-slow: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);




:root {
	//main
	--h1Size: 6rem;
	--h2Size: 4.6rem;
	--h3Size: 2.6rem;
	--h4Size: 2.3rem;
	--h5Size: 2.1rem;
	--h6Size: 1.8rem;


	@media (max-width: 1023px) {
		--h1Size: clamp(3.6rem, 9vw, 6rem);
		--h2Size: clamp(3.2rem, 6.5vw, 4.6rem);
		--h3Size: clamp(2.2rem, 6vw, 2.6rem);
		--h4Size: clamp(2rem, 4.7vw, 2.3rem);
		--h5Size: clamp(1.6rem, 4.3vw, 2.1rem);
		--h6Size: clamp(1.6rem, 4vw, 1.8rem);
	}

	//colors
	@include defineColorRGB(--color-text, #262626);
	--color-main: #F8704E;
	--color-accent: #35CA56;
	--color-gold: #FFB635;
	--color-bg: #ffffff;
	--color-border: #F3F3F3;
	--color-border_dark: #DEDEDE;
	--color-text_light: #ABACB5;
	// --gr-main: linear-gradient(96.2deg, #F1482B 7.85%, #FF9C74 103.93%);

	@media print {
		--color-text: #000000;
		--color-bg: #ffffff;
	}

	@media (prefers-color-scheme: dark) {
		html:not(.light-theme) {
			@include defineColorRGB(--color-text, #d9d9d9);
			--color-bg: #161616;
			--color-border: #{rgba(#F3F3F3, .2)};
		}
	}
}



.dark-theme {
	@include defineColorRGB(--color-text, #d9d9d9);
	--color-bg: #161616;
	--color-border: #{rgba(#F3F3F3, .2)};

	@media print {
		--color-text: #000000;
		--color-bg: #ffffff;
	}
}





