@font-face {
    font-family: 'Cera Round Pro';
    src: url('../fonts/CeraRoundPro/CeraRoundPro-Light.woff2') format('woff2'),
        url('../fonts/CeraRoundPro/CeraRoundPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Cera Round Pro';
    src: url('../fonts/CeraRoundPro/CeraRoundPro-Medium.woff2') format('woff2'),
        url('../fonts/CeraRoundPro/CeraRoundPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Cera Round Pro';
    src: url('../fonts/CeraRoundPro/CeraRoundPro-Bold.woff2') format('woff2'),
        url('../fonts/CeraRoundPro/CeraRoundPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Cera Round Pro';
    src: url('../fonts/CeraRoundPro/CeraRoundPro-Black.woff2') format('woff2'),
        url('../fonts/CeraRoundPro/CeraRoundPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans/GeneralSans-LightItalic.woff2') format('woff2'),
        url('../fonts/GeneralSans/GeneralSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans/GeneralSans-Regular.woff2') format('woff2'),
        url('../fonts/GeneralSans/GeneralSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans/GeneralSans-Medium.woff2') format('woff2'),
        url('../fonts/GeneralSans/GeneralSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans/GeneralSans-Semibold.woff2') format('woff2'),
        url('../fonts/GeneralSans/GeneralSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
