.tooltipster-fall, .tooltipster-grow.tooltipster-show
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15)

.tooltipster-base
  display: flex
  pointer-events: none
  position: absolute

.tooltipster-box
  flex: 1 1 auto

.tooltipster-content
  box-sizing: border-box
  max-height: 100%
  max-width: 100%
  overflow: auto

.tooltipster-ruler
  bottom: 0
  left: 0
  overflow: hidden
  position: fixed
  right: 0
  top: 0
  visibility: hidden

.tooltipster-fade
  opacity: 0
  transition-property: opacity
  &.tooltipster-show
    opacity: 1

.tooltipster-grow
  transform: scale(0, 0)
  transition-property: transform
  -webkit-backface-visibility: hidden
  &.tooltipster-show
    transform: scale(1, 1)
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15)

.tooltipster-swing
  opacity: 0
  transform: rotateZ(4deg)
  transition-property: transform
  &.tooltipster-show
    opacity: 1
    transform: rotateZ(0)
    transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4)

.tooltipster-fall
  transition-property: top
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15)
  &.tooltipster-initial
    top: 0 !important
  &.tooltipster-dying
    transition-property: all
    top: 0 !important
    opacity: 0

.tooltipster-slide
  transition-property: left
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15)
  &.tooltipster-initial
    left: (-40px) !important
  &.tooltipster-dying
    transition-property: all
    left: 0 !important
    opacity: 0

@keyframes tooltipster-fading
  0%
    opacity: 0

  100%
    opacity: 1


.tooltipster-update-fade
  animation: tooltipster-fading .4s

@keyframes tooltipster-rotating
  25%
    transform: rotate(-2deg)

  75%
    transform: rotate(2deg)

  100%
    transform: rotate(0)


.tooltipster-update-rotate
  animation: tooltipster-rotating .6s

@keyframes tooltipster-scaling
  50%
    transform: scale(1.1)

  100%
    transform: scale(1)


.tooltipster-update-scale
  animation: tooltipster-scaling .6s


.tooltipster-sidetip
  .tooltipster-box
    background: #565656
    border: 2px solid #000
    border-radius: 4px
  &.tooltipster-bottom .tooltipster-box
    margin-top: 8px
  &.tooltipster-left .tooltipster-box
    margin-right: 8px
  &.tooltipster-right .tooltipster-box
    margin-left: 8px
  &.tooltipster-top .tooltipster-box
    margin-bottom: 8px
  .tooltipster-content
    color: #fff
    line-height: 18px
    padding: 10px
  .tooltipster-arrow
    overflow: hidden
    position: absolute
  &.tooltipster-bottom .tooltipster-arrow
    height: 10px
    margin-left: -10px
    top: 0
    width: 20px
  &.tooltipster-left .tooltipster-arrow
    height: 20px
    margin-top: -10px
    right: 0
    top: 0
    width: 10px
  &.tooltipster-right .tooltipster-arrow
    height: 20px
    margin-top: -10px
    left: 0
    top: 0
    width: 10px
  &.tooltipster-top .tooltipster-arrow
    bottom: 0
    height: 10px
    margin-left: -10px
    width: 20px
  .tooltipster-arrow-background, .tooltipster-arrow-border
    height: 0
    position: absolute
    width: 0
  .tooltipster-arrow-background
    border: 10px solid transparent
  &.tooltipster-bottom .tooltipster-arrow-background
    border-bottom-color: #565656
    left: 0
    top: 3px
  &.tooltipster-left .tooltipster-arrow-background
    border-left-color: #565656
    left: -3px
    top: 0
  &.tooltipster-right .tooltipster-arrow-background
    border-right-color: #565656
    left: 3px
    top: 0
  &.tooltipster-top .tooltipster-arrow-background
    border-top-color: #565656
    left: 0
    top: -3px
  .tooltipster-arrow-border
    border: 10px solid transparent
    left: 0
    top: 0
  &.tooltipster-bottom .tooltipster-arrow-border
    border-bottom-color: #000
  &.tooltipster-left .tooltipster-arrow-border
    border-left-color: #000
  &.tooltipster-right .tooltipster-arrow-border
    border-right-color: #000
  &.tooltipster-top .tooltipster-arrow-border
    border-top-color: #000
  .tooltipster-arrow-uncropped
    position: relative
  &.tooltipster-bottom .tooltipster-arrow-uncropped
    top: -10px
  &.tooltipster-right .tooltipster-arrow-uncropped
    left: -10px