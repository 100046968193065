.line-icons {
    @extend %reset-list;

    &.grid {
        @include r(767){
            --bs-columns: 2;
        }
        @include rIn(768,1023) {
            --bs-columns: 3;
        }
        @include rmin(1024) {
            --bs-columns: 6;
        }
    }
}

.li-item {
    border: 1px solid var(--color-border);
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    perspective: 100px;
    user-select: none;
    &:not(.li-item_active){
        opacity: .3;
        filter: grayscale(100%);
        pointer-events: none;
    }
    a {
        color: var(--color-text);
        text-decoration: none;
    }
    &.li-item_active{
        position: relative;
        transition: transform $transition-slow, box-shadow $transition-base;
        box-shadow: 0 0 0 rgba(0,0,0,0);
        &:hover {
            transform: translateY(-.5em);
            box-shadow: 0px 5px 25px alpha(var(--color-bg-invert), 0.1);
        }
    }

    &__link {
        position: relative;
        padding: 2em 0.5em em(72, 18);
        display: block;
        width: 100%;
    }

    &__name {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}