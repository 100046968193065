@import "utils/mixins";
@import "utils/vars";
@import "utils/fonts";
@import "utils/normalize";
@import "utils/css-variables__utils";
// @import "flatpickr/dist/flatpickr.min"; // no need ./node_modules
// $aos-distance: 20px; // It has to be above import
// @import 'aos/src/sass/aos.scss';


// $enable-grid-classes: false;
// $enable-cssgrid: true;
// $enable-container-classes: false;
// $grid-columns: 20;
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/bootstrap-grid";


// .g-container {
// 	@include make-container();
// }

@import 'lib/index';

html,
body {
	height: 100%;
}


html {
	font-size: var(--fz);
	line-height: $main-line-height;
	color-scheme: dark light;
	@include rmin(1024){
		min-width: mAx(1024px,90vw);
	}
	// &.light-theme {
	// 	color-scheme: light;
	// }

	// &.dark-theme {
	// 	color-scheme: dark;
	// }

	// // scroll-padding: 0 0 0 0;

	// overflow: scroll;
	// scroll-behavior: smooth;

	// @supports (scrollbar-gutter: stable) {
	// 	overflow: auto;
	// 	scrollbar-gutter: stable;
	// }

	& {
		scrollbar-width: thin;
		scrollbar-color: var(--color-text) var(--color-border);
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: var(--color-border);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-text);
		border-radius: 12px;
	}
}


body {
	margin: 0;
	padding: 0;
	font-family: $main-font;
	font-weight: $main-font-weight;
	color: var(--color-text);
	background-color: var(--color-bg);
	transition: background-color .23s ease;
	@extend %antialias;
	width: 100%;
	position: relative;
	&.overflow{
		overflow: hidden
	}

	@include r(1023) {
		@include css-lock(16, 20, 320, 768);
		// font-size: 2rem;
	}

	scrollbar-gutter: stable;

	&.menu-open {
		overflow: hidden;
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

img,
svg {
	max-width: 100%;
}

picture {
	line-height: 0;
}

svg {
	fill: currentColor;
	max-height: 100%;
}

button,
a {
	cursor: pointer;
}

a {
	color: currentColor;
}

strong,
.bold {
	font-weight: 700;
}

.layout {
	position: relative;
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;

	&__header,
	&__footer {
		flex-shrink: 0;
	}

	&__header {
		z-index: 100;
	}

	&__main {
		// padding-bottom: 7vmax;
		flex-grow: 1;
		// overflow: hidden;
		position: relative;
	}
}

.container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	max-width: 1176px;
}

.tooltip_templates {
	display: none;

	&_icon-description {
		display: none !important;
	}
}

.tooltipster-content {
	.clear-list {
		margin: 0;
	}
}

.clear-list {
	@extend %reset-list;
	margin-top: 1em;
	margin-bottom: 1em;
}

// Components
@import "components/index";
@import "pages/index.sass";

// Utils
@import "utils/utils";



@media screen and (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.001ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.001ms !important;
	}
}

@media (inverted-colors) {

	img,
	video {
		filter: invert(100%);
	}
}

@media (prefers-color-scheme: dark) {
	img {
		filter: brightness(.8) contrast(1.2);
	}
}

.dark-theme {
	img {
		filter: brightness(.8) contrast(1.2);
	}
}


img[width] {
	height: auto;
}

figure {
	margin: 1em auto;
}

.layout__main {
	ol:not(.breadcrumbs) {
		@extend %reset-list;
		margin-top: 1em;
		margin-bottom: 1em;
		counter-reset: listT;

		>li h2:first-child::before,
		>li h3:first-child::before {
			color: inherit;
			content: counter(listT) '. ';
			counter-increment: listT;
		}

		>li .num-place:first-child::before {
			content: counter(listT);
			counter-increment: listT;
		}
	}
}




.elem-icon {
	display: block;
	position: relative;
	padding-left: 2em;
	min-height: calc(24/16)*1em;

	&::before {
		content: '';
		position: absolute;
		width: 1em;
		height: 1em;
		left: 0;
		top: .3em;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&_good {
		&::before {
			// background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 9.99999C20 11.9739 19.414 13.8934 18.3056 15.5513C18.155 15.7767 17.9075 15.8984 17.6555 15.8984C17.5062 15.8984 17.3553 15.8558 17.222 15.7666C16.8632 15.5269 16.767 15.0416 17.0067 14.6829C17.9428 13.2829 18.4375 11.6637 18.4375 9.99999C18.4375 9.28511 18.3481 8.57512 18.1717 7.89C18.0641 7.47222 18.3157 7.04619 18.7335 6.93862C19.1516 6.8312 19.5773 7.08266 19.6849 7.50045C19.8939 8.31282 20 9.15374 20 9.99999ZM14.3646 17.2224C13.0524 18.0172 11.5431 18.4375 9.99999 18.4375C5.34759 18.4375 1.5625 14.6524 1.5625 9.99999C1.5625 5.34759 5.34759 1.5625 9.99999 1.5625C11.7226 1.5625 13.3789 2.07794 14.7902 3.05313C15.1451 3.29834 15.6317 3.20938 15.8771 2.85446C16.1223 2.49954 16.0335 2.01294 15.6784 1.76758C14.0048 0.611266 12.0413 0 9.99999 0C7.32894 0 4.81765 1.04019 2.92892 2.92892C1.04019 4.81765 0 7.32894 0 9.99999C0 12.671 1.04019 15.1823 2.92892 17.0711C4.81765 18.9598 7.32894 20 9.99999 20C11.8286 20 13.6178 19.5016 15.1742 18.5588C15.5434 18.3352 15.6613 17.8549 15.4378 17.4858C15.2141 17.1167 14.7337 16.9989 14.3646 17.2224ZM10.0754 12.2653C9.95162 12.2702 9.83611 12.2262 9.74639 12.1408L5.65475 8.26125C5.34164 7.96431 4.8471 7.97744 4.55032 8.29055C4.25338 8.60366 4.26651 9.09819 4.57962 9.39498L8.67049 13.2739C9.0483 13.6331 9.53704 13.8281 10.0549 13.8281C10.0827 13.8281 10.1105 13.8275 10.1382 13.8264C10.6868 13.8043 11.1906 13.5669 11.5568 13.1578C11.5662 13.1476 11.575 13.137 11.5837 13.1262L19.8276 2.87231C20.0979 2.53601 20.0445 2.04422 19.7082 1.77398C19.3721 1.5036 18.8801 1.557 18.6098 1.89331L10.3821 12.1272C10.3012 12.2116 10.1929 12.2606 10.0754 12.2653Z' fill='%2335CA56'/%3E%3C/svg%3E%0A");
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6515 1.158C14.1874 0.69328 13.4337 0.693573 12.969 1.158L5.3968 8.73049L2.03129 5.36501C1.56656 4.90029 0.813261 4.90029 0.34854 5.36501C-0.11618 5.82973 -0.11618 6.58303 0.34854 7.04775L4.55526 11.2545C4.78747 11.4867 5.09196 11.6031 5.39648 11.6031C5.701 11.6031 6.00579 11.487 6.238 11.2545L14.6515 2.84072C15.1162 2.37632 15.1162 1.62269 14.6515 1.158Z' fill='%2335CE57'/%3E%3C/svg%3E%0A");
		}
	}

	&_bad {
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='459.313px' height='459.314px' viewBox='0 0 459.313 459.314' style='enable-background:new 0 0 459.313 459.314;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M459.313,229.648c0,22.201-17.992,40.199-40.205,40.199H40.181c-11.094,0-21.14-4.498-28.416-11.774 C4.495,250.808,0,240.76,0,229.66c-0.006-22.204,17.992-40.199,40.202-40.193h378.936 C441.333,189.472,459.308,207.456,459.313,229.648z' fill='%23FB5C41'/%3E%3C/g%3E%3C/svg%3E%0A");
		}
	}

	&_danger {
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='12' rx='2' fill='%23FB5C41'/%3E%3C/svg%3E%0A");
		}
	}

	&_tips {
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='12' rx='2' fill='%2335CE57'/%3E%3C/svg%3E%0A");
		}
	}

	&_warn {
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='12' rx='2' fill='%23FCBE1E'/%3E%3C/svg%3E%0A");
		}
	}

	&_thumbs-down {
		&::before {
			background-image: url(#{$pathPrefix}img/icons/unlike.svg);
		}
	}

	&_thumbs-up {
		&::before {
			background-image: url(#{$pathPrefix}img/icons/like.svg);
		}
	}
}


.header-trunk-line_2 {
	padding: 0;
	height: 2.5em;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.hidden {
	display: none !important;
}

/// safe-area
// .cookieNotice {
//     padding-bottom calc( env(safe-area-inset-bottom) + 20px )
// }


.bordered {
	border: 1px solid var(--color-border);
	border-radius: 5px;
}


.g-columns-1-2-4 {
	--bs-columns: 1;

	@include rIn(768, 1023) {
		--bs-columns: 2;
	}

	@include rmin(1024) {
		--bs-columns: 4;
	}
}

.iconWithTooltip {
	display: inline-flex;
}

.logo{
	position: relative;
	&::before{
		content: '';
		display: block;
		padding-bottom: calc(calc(45/185) * 100%);
	}
	.svg-icon{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
