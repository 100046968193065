@function rem($pixels, $context: $main-font-size) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return calc($pixels / $context) * 1rem;
}

@function em($pixels, $context: $main-font-size) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return calc($pixels / $context) * 1em;
}

@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}


@function breakpoint($key) {
	@if map-has-key($breakpoints, $key) {
		@return map-get($breakpoints, $key);
	}

	@warn "Unknown `#{$key}` in $breakpoints.";
	@return null;
}

@function z($name) {
	@if index($z-indexes, $name) {
		@return (length($z-indexes) - index($z-indexes, $name))+10;
	}

	@else {
		@warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
		@return null;
	}
}

%antialias {
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

%overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: inherit;
}

%overlay-full {
	@extend %overlay;
	width: 100%;
	height: 100%;
}

%text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

%bg-cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

%bg-content,
%bg-contain {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

%gpu {
	transform: translate3d(0, 0, 0);
}

%reset-btn {
	border: 0;
	border-radius: 0;
	box-shadow: none;
	background: none;
	padding: 0;
	margin: 0;
	outline: none;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%reset-list {
	padding: 0;
	margin: 0;
	list-style: none;
}

%unselectable {
	-webkit-touch-callout: none;
	user-select: none;
}

%hide-scrollbar {
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin media($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);

		@if ($type==max) {
			$width: $width - 1px;
		}

		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

@mixin r($width) {
	@media only screen and (max-width: $width + "px") {
		@content;
	}
}

@mixin rmin($width) {
	@media only screen and (min-width: $width + "px") {
		@content;
	}
}

@mixin rIn($width, $width2) {
	@media (min-width: $width + "px") and (max-width: $width2 + "px") {
		@content;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}

	&:-moz-placeholder {
		@content
	}

	&::-moz-placeholder {
		@content
	}

	&:-ms-input-placeholder {
		@content
	}
}

@mixin aspect-ratio($w, $h, $pseudo: false) {
	$padding: unquote($h / $w * 100 + '%');
	position: relative;

	@if $pseudo {
		&::before {
			content: "";
			display: block;
			width: 100%;
			padding-top: $padding;
		}
	}

	@else {
		padding-top: $padding;
	}
}

@mixin hover {
	.no-touch &:hover {
		@content
	}
}

@mixin abs-center($axis: both) {
	position: absolute;

	@if ($axis==h) {
		left: 50%;
		transform: translateX(-50%);
	}

	@else if ($axis==v) {
		top: 50%;
		transform: translateY(-50%);
	}

	@else {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin retina {

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (-moz-min-device-pixel-ratio: 1.5),
	only screen and (-o-min-device-pixel-ratio: 3 / 2),
	only screen and (min-device-pixel-ratio: 1.5),
	only screen and (min-resolution: 1.5dppx) {
		@content;
	}
}

@mixin gradient($start-color, $end-color, $orientation: v) {
	background: $start-color;

	@if ($orientation==r) {
		background-image: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
		background-image: radial-gradient(ellipse at center, $start-color, $end-color);
	}

	@else if ($orientation==h) {
		background-image: -webkit-linear-gradient(left, $start-color, $end-color);
		background-image: linear-gradient(to right, $start-color, $end-color);
	}

	@else {
		background-image: -webkit-linear-gradient(top, $start-color, $end-color);
		background-image: linear-gradient(to bottom, $start-color, $end-color);
	}
}

@mixin selection {
	&::-moz-selection {
		@content;
	}

	&::selection {
		@content;
	}
}

@mixin counter($var, $sep) {
	counter-reset: list + $var;

	>li::before {
		content: counter(list + $var) $sep;
		counter-increment: list + $var;
	}
}

@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px) {
	#{$property}: #{$max-size}#{$unit};
	#{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));

	@media (max-width: #{$min-width}#{$unit}) {
		#{$property}: #{$min-size}#{$unit}
	}

	@media (min-width: #{$max-width}#{$unit}) {
		#{$property}: #{$max-size}#{$unit}
	}
}

@mixin arr($width, $height, $bg, $direction) {
	width: 0;
	height: 0;
	border-style: solid;

	@if ($direction==t) {
		border-width: 0 $width / 2 + px $height + px $width / 2 + px;
		border-color: transparent transparent $bg transparent;
	}

	@if ($direction==r) {
		border-width: $height / 2 + px 0 $height / 2 + px $width + px;
		border-color: transparent transparent transparent $bg;
	}

	@if ($direction==b) {
		border-width: $height + px $width / 2 + px 0 $width / 2 + px;
		border-color: $bg transparent transparent transparent;
	}

	@if ($direction==l) {
		border-width: $height / 2 + px $width + px $height / 2 + px 0;
		border-color: transparent $bg transparent transparent;
	}

	@if ($direction==tl) {
		border-width: $height + px $width + px 0 0;
		border-color: $bg transparent transparent transparent;
	}

	@if ($direction==tr) {
		border-width: 0 $width + px $height + px 0;
		border-color: transparent $bg transparent transparent;
	}

	@if ($direction==br) {
		border-width: 0 0 $height + px $width + px;
		border-color: transparent transparent $bg transparent;
	}

	@if ($direction==bl) {
		border-width: $width + px 0 0 $height + px;
		border-color: transparent transparent transparent $bg;
	}
}

@mixin hardware($backface: true, $perspective: 1000) {
	@if $backface {
		backface-visibility: hidden;
	}

	perspective: $perspective;
}

%flex-row {
	display: flex;
	flex-direction: row;
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: none;
}

%flex-stretch {
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
}

%container_full {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
}


@mixin underlineOnHover($param: 1px) {
	position: relative;
	text-decoration: none;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		bottom: 0;
		left: 0;
		background-color: currentColor;
		transform: translateY(3px);
		transition: $transition-base;
		backface-visibility: hidden;
		opacity: 0;
		pointer-events: none;
	}

	&:hover {
		text-decoration: none;

		&::after {
			content: '';
			transform: translateY(0);
			opacity: 1;
		}
	}
}

@mixin defineColorRGB($color-name, $value) {
	$red: red($value);
	$green: green($value);
	$blue: blue($value);
	#{$color-name}: unquote("rgb(#{$red}, #{$green}, #{$blue})");
	#{$color-name}-rgb: $red, $green, $blue;
	#{$color-name}-r: $red;
	#{$color-name}-g: $green;
	#{$color-name}-b: $blue;
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@function alpha($color, $opacity) {
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-r: var(#{$color+'-r'});
	$color-g: var(#{$color+'-g'});
	$color-b: var(#{$color+'-b'});
	@return rgba($color-r, $color-g, $color-b, $opacity);
}

@mixin blur() {
	&:hover {
		animation: blur-a 3s ease-out;
		text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
	}
}

@keyframes blur-a {
	from {
		text-shadow: 0px 0px 10px #fff,
			0px 0px 10px #fff,
			0px 0px 25px #fff,
			0px 0px 25px #fff,
			0px 0px 25px #fff,
			0px 0px 25px #fff,
			0px 0px 25px #fff,
			0px 0px 25px #fff,
			0px 0px 50px #fff,
			0px 0px 50px #fff,
			0px 0px 50px #7B96B8,
			0px 0px 150px #7B96B8,
			0px 10px 100px #7B96B8,
			0px 10px 100px #7B96B8,
			0px 10px 100px #7B96B8,
			0px 10px 100px #7B96B8,
			0px -10px 100px #7B96B8,
			0px -10px 100px #7B96B8;
	}
}


