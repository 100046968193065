@mixin header-btn($color,$bg,$bgHover: darken($bg,10%), $iconBg: $color-white, $iconColor: $color-primary){
	border: none;
	font-size: calc(var(--fz) * 1.14);
	padding: .5em;
	border-radius: 60px;
	background-color: $bg;
	color: $color;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover{
		background-color: $bgHover;
	}
	.text{
		padding: 0 1.25em;
		font-weight: 500;
	}
	.icon{
		width: 2em;
		height: 2em;
		background-color: $iconBg;
		color:  $iconColor;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		svg{
			width: 1em;
			height: .8em;
		}
	}
}


.header {
	&__container {
		margin: 0 auto;
		padding: 0.7em 1em;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include r(1023){
			.header__try,
			.header__sendRequest,
			.header__toMainSite{
				.text{
					padding: 0 .4em;
				}
			}
		}
		@include r(767){
			flex-flow: row wrap;
			.logo{
				flex: 1 1 100%;
				max-width: 100%;
				width: 100%;
				&::before{
					padding-bottom: 5.5rem;
				}
				.svg-icon{
					max-width: mIn(38%, 15rem);
				}
			}
			.header__try{
				display: none;
			}
			.header__sendRequest{
				margin: 0;
				flex: 1 1 40%;
				max-width: 40%;
			}
			.header__toMainSite{
				flex: 1 1 58%;
				max-width: 58%;
			}
			.header__sendRequest,
			.header__toMainSite{
				.text{
					padding: 0 .4em;
				}
			}
		}
	}

	&__nav {

	}


	.logo {
		width: rem(180);
	}
	&__toMainSite{
		@include header-btn($color-white,$color-primary);
		min-height: 3em;
	}
	&__sendRequest{
		@include header-btn($color-white,$color-primary-200);
		margin-right: 1em;
		min-height: 3em;
		.icon{
			transform: rotate(90deg);
		}
	}
	&__try{
		@include header-btn($color-white,$color-primary-200);
		margin-left: auto;
		margin-right: 1em;
		min-height: 3em;
		.icon{
			transform: rotate(-90deg);
		}
	}
	&_simple{
		.header__back,
		.header__video{
			display: none;
		}
		.header__container{
			padding: 0;
		}
	}
	&_setup{
		.header__container{
			padding: 15px 20px;
		}
		.header__toMainSite{
			display: none;
		}
		.header__back{
			@include header-btn($color-white,$color-bg,$iconColor: $color-bg);
			.icon{
				transform: rotate(180deg);
				svg{
					transition: transform .23s;
					transform-origin: 100% center
				}
			}
			&:hover{
				.icon svg{
					transform:  translateX(.3em) scaleX(1.2);
				}
			}

		}
		.header__video{
			@include header-btn($color-bg,$color-primary, $iconBg: $color-bg);
		}
		.logo{
			max-width: 98px
		}
	}
}


.nav,
._has-child ul {
	@extend %reset-list;
}

._has-child {
	position: relative;

	@include rmin($menuMob) {
		&>a:not([href^="#"]) {
			position: relative;
		}

		&::before {
			content: '';
			width: calc(100% + 2em);
			height: calc(100% + 2em);
			position: absolute;
			left: -.5em;
			top: -1em;

		}

		&::after {
			content: '';
			position: absolute;
			left: calc(100% + .3em);
			top: .5em;
			width: .7em;
			height: 0.7em;
			background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6925 0.692499L6.03564 6.34935L0.37879 0.692499' stroke='%23F8704E'/%3E%3C/svg%3E%0A");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transition: $transition-base;
		}
	}

	.child-list {

		// width: 100%;
		@include rmin($menuMob) {
			white-space: nowrap;
			padding: .5em .8em;
			position: absolute;
			opacity: 0;
			transform: translateY(-.4em);
			min-width: 12em;
			pointer-events: none;
			transition: $transition-base;
			backdrop-filter: blur(10px);
			// &.overscrolled{
			//     margin-left: var(--overscrolled-gap, 0);
			// }
		}

		@include r($menuMob - 1) {
			margin-bottom: 1em;
			padding-right: 0.5em;
			font-size: 80%;

			li {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					width: .3em;
					height: 1px;
					background-color: currentColor;
					left: calc(100% + .2em);
					top: 0.75em;
					opacity: .3;
				}
			}
		}

		li {
			margin-bottom: 0.3em;

			a {
				display: block;
				transition: transform $transition-base;

				&:hover {
					transform: translateX(.3em);
				}
			}
		}

		&::before {
			content: '';
			@extend %overlay-full;
			z-index: -1;
			background: rgba(255, 255, 255, 0.25);
			box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
			// backdrop-filter: blur( 5px );
			border-radius: 5px;

			@include r($menuMob - 1) {
				content: none;
			}
		}
	}

	&.is-open {
		@include rmin($menuMob) {
			.child-list {
				opacity: 1;
				transform: none;
				pointer-events: auto;
			}

			&::after {
				transform: rotate(180deg);
			}
		}
	}
}

._has-child {
	@include rmin($menuMob) {
		&:nth-child(3) {
			.child-list {
				left: auto;
				right: -80%;
			}
		}

		&:nth-child(4) {
			.child-list {
				left: auto;
				right: -1.5em;
			}
		}
	}
}

.nav {
	&.nav-header {
		display: flex;
		align-items: center;

		@include r($menuMob - 1) {
			display: block;
			text-align: right;
			padding: 100px 2em;
			font-size: 3vmax;
			max-height: 100%;
			overflow-y: auto;
		}

		@include rmin($menuMob) {
			>li+li {
				margin-left: 2em;
			}
		}
	}

	a {
		text-decoration: none;
		color: currentColor;
	}

	li {
		@extend %show;
	}
}


.header__trigger {
	position: relative;
	margin-right: 10px;
	margin-left: 20px;
	z-index: 10;
	width: 14px;
	height: 16px;
	color: currentColor;
	display: flex;
	align-items: center;
	pointer-events: all;

	&::before {
		content: '';
		position: absolute;
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		top: -10px;
		left: -10px;
		background: transparent;
	}

	@include rmin($menuMob) {
		display: none;
	}

	span {
		margin-left: auto;
		display: block;
		width: 100%;
		height: 2px;
		background-color: currentColor;
		position: relative;
		transition: transform $transition-base;
		border-radius: 5px;

		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 21px;
			height: 100%;
			left: 0;
			background-color: currentColor;
			transition: inherit;
			border-radius: inherit;
		}

		&::before {
			top: -7px;
			transform-origin: 100% 35%;
		}

		&::after {
			bottom: -7px;
			transform-origin: 100% 65%;
		}

		.menu-open & {
			background-color: transparent;

			&::before {
				transform: rotate(-45deg);
				background-color: var(--color-text);
			}

			&::after {
				transform: rotate(45deg);
				background-color: var(--color-text);
			}
		}
	}
}




@keyframes underline-decor {
	0% {
		opacity: 1;
		transform: scaleX(.6) scaleY(.2);
	}

	50% {
		opacity: 1;
		transform: scaleX(.6) scaleY(2);
	}

	100% {
		opacity: 1;
		transform: scaleX(1) scaleY(1);
	}
}



%show {
	@include r($menuMob - 1) {
		opacity: 0;

		.menu-open & {
			animation: slide 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
		}

		@for $i from 1 through 10 {
			&:nth-child(#{$i}) {
				animation-delay: .2s + ($i * .1s);
			}
		}
	}

}

@keyframes slide {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes show {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
