.tooltipster-sidetip.tooltipster-shadow,
.tooltipster-sidetip.tooltipster-shadow2
  .tooltipster-box
    border: none
    border-radius: 5px
    background: var(--color-bg, #fff)
    box-shadow: 0px 0px 15px rgba(#262626, 0.1)
    .dark-theme &
      box-shadow: 0px 0px 15px rgba(#d9d9d9, 0.1)

  &.tooltipster-bottom .tooltipster-box
    margin-top: 6px
  &.tooltipster-left .tooltipster-box
    margin-right: 6px
  &.tooltipster-right .tooltipster-box
    margin-left: 6px
  &.tooltipster-top .tooltipster-box
    margin-bottom: 6px
  .tooltipster-content
    color: var(--color-text,#8d8d8d)
  .tooltipster-arrow
    height: 6px
    margin-left: -6px
    width: 12px
  &.tooltipster-left .tooltipster-arrow, &.tooltipster-right .tooltipster-arrow
    height: 12px
    margin-left: 0
    margin-top: -6px
    width: 6px
  .tooltipster-arrow-background
    display: none
  .tooltipster-arrow-border
    border: 6px solid transparent
  &.tooltipster-bottom .tooltipster-arrow-border
    border-bottom-color: var(--color-bg, #fff)
  &.tooltipster-left .tooltipster-arrow-border
    border-left-color: var(--color-bg, #fff)
  &.tooltipster-right .tooltipster-arrow-border
    border-right-color: var(--color-bg, #fff)
  &.tooltipster-top .tooltipster-arrow-border
    border-top-color: var(--color-bg, #fff)
  &.tooltipster-bottom .tooltipster-arrow-uncropped
    top: -6px
  &.tooltipster-right .tooltipster-arrow-uncropped
    left: -6px


.tooltipster-sidetip.tooltipster-shadow2
  .tooltipster-content
    font-size: $main-font-size * .88
    line-height: normal
    letter-spacing: .05em
    color: var(--color-text, #333333)

  .-uppercase
    text-transform: uppercase
    .tooltipster-content
      font-size: 11px

  // &.tooltipster-shadow2_min
  //   --mainFz: 14px

.tooltipster-base.tooltipster-drop
  transform: translateX(34%) translateY(-20px)
  +r(991)
    transform: translateX(0%) translateY(-20px)
  opacity: 0
  &.tooltipster-show
    transform: translateX(0%) translateY(0px)
    +r(991)
      transform: translateX(0%) translateY(0px)
    opacity: 1
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15)
