//component setting
//all

:root{
    --smSize: 1rem;
}
$global_font-size: var(--basicSize);
@media (min-width: 1700px) and (max-width: 2000px) and (max-height: 979px){
    .form{
        --basicSize: calc(var(--basicUnit) * 1.3);
    }
}


//lines
$lines_margin-bottom: em(12);

//field
$field_part-padding: .3em;
$field_font-size: 1.4rem;
$field_inner-padding: em(10,14) 0;
$field_border-radius: 0;
$field_border-width: 1px;
$field_border-color: #D6D6D6;
$field_border-color--error: #ff3b3b;
$field_bg-color: transparent;
$field_select-opt-bg: var(--color-white);

$field_error-color: var(--color-bg);
$field_error-bg: #ff3b3b;


@mixin field_autofill($color) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus{
        border-color: $color;
        -webkit-text-fill-color: $color;
        // -webkit-box-shadow: 0 0 0px 1000px rgba(#000,.01) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}
@mixin field_focus(){
    &:focus,
    &:focus-within{
        outline: 1px dashed currentColor;
    }
}



.form{
    font-size: $global_font-size;
    &__line{
        margin-bottom: $lines_margin-bottom;
        display: flex;
        width: 100%;
        & > .form__line{
            @extend .form__field;
            margin-bottom: 0;
        }
    }
    &__field{
        flex: 1 1 100%;
        max-width: 100%;
        position: relative;
        padding-left: $field_part-padding;
        padding-right: $field_part-padding;
        font-size: $field_font-size;
        @media (max-width: 767px) and (orientation: portrait) {
            font-size: 14px;
        }
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
        &.half{
            flex: 1 1 50%;
            max-width: 50%;
        }
        &.one-of-three{
            flex: 1 1 33.33%;
            max-width: 33.33%;
        }
        &.two-of-three{
            flex: 1 1 66.666%;
            max-width: 66.666%;
        }
        &.one-of-four{
            flex: 1 1 25%;
            max-width: 25%;
        }
        &.three-of-four{
            flex: 1 1 75%;
            max-width: 75%;
        }
        input,
        select,
        textarea{
            -webkit-appearance: none;
            padding: $field_inner-padding;

            display: block;
            width: 100%;
            background-color: $field_bg-color;
            border-radius: $field_border-radius;
            border: none;
            border-bottom: $field_border-width solid $field_border-color;
            line-height: 1.2;
            transition: all $transition-base;

            &.input-error{
                border-color: $field_border-color--error;
            }
            @include placeholder{
                color: currentColor;
                opacity: .9;
            }
            @include field_focus();
            @include field_autofill($color-bg);


            &:invalid{
                border-color: $field_error-bg;
                background-color: rgba($field_error-bg, .1);
            }
            &:focus:invalid {
                outline: none;
            }
        }
        textarea{
            resize: vertical;
        }
        select{
            // padding-left: em(20,14);
            padding-left: 0;
            padding-right: em(20,14);
            -webkit-tap-highlight-color: transparent;
            option{
                background-color: $field_select-opt-bg;
                &[value=""][disabled] {
                    display: none;
                }
            }
        }

    }
    &__accepted{
        margin-top: em(35);
        display: block;
    }
    &__checkbox{
        position: relative;
        font-size: var(--smSize);
        display: flex;
        &:hover{
            cursor: pointer;
        }
        input{
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            &:checked ~ .pseudo{
				background: #000;
                &::after{
                    opacity: 1;
                }
            }
        }
        .pseudo{
            margin-top: 0.1em;
            margin-right: 1em;
            // width: 1.1em;
            // height: 1.1em;
            width: 1.3em;
            height: 1.3em;
            border-radius: 3px;
            border: 1px solid;
            position: relative;
            &::after{
                content: '';
                // width: .7em;
                // height: .7em;
                // background-color: currentColor;
                width: 1.35em;
                height: 1.35em;
                @include abs-center();
                transition: $transition-base;
                opacity: 0;
                background-image: url(/img/decor/checkbox.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .text{
            flex: 1;
            p{
                margin-top: 0;
            }
            a{
                color: currentColor;
                &:hover{
                    text-decoration: none;
                }
            }
        }

    }

    .error {
        position: absolute;
        bottom: calc(100% + .2em);
        border-radius: 10px 0 10px 0;
        padding: 0.3em 1em .1em;
        font-size: 75%;
        display: inline-block;
        width: auto;
        line-height: 1.2;
        color: $field_error-color;
        background-color: $field_error-bg;
    }

    &__radio{
        @extend .form__checkbox;
        .pseudo{
            margin-top: 0.1em;
            border-radius: 50%;
            &::after{
                border-radius: 50%;
                height: calc(100% - 3px);
            }
        }
    }
    &__submit{
        margin-top: em(55);
        display: block;
        text-align: right;
        button{

        }
    }
    .hidden{
        display: none !important;
    }
	.form-loader{
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(255,255,255,.3);
		backdrop-filter: blur(2px);
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 100;
		opacity: 0;
		pointer-events: none;
	}
	&.is-sending{
		.form__wrapper{
			pointer-events: none;
		}
		.form-loader{
			opacity: 1;
		}
	}
}

.form__field:not(.form__field_active){
    select{
        color: transparent;
    }
}


// new behavior like in material design
.form{
    .form__line{
        margin-bottom: 1.7em;
    }
    .error{
        left: 0;
        background-color: transparent;
        color: $field_border-color--error;
        bottom: unset;
        top: calc(100% + 2px);
        line-height: 1;
        padding: 0;
        font-size: $field_font-size * 0.65;
    }
    .form__label{
        // padding: 0 4px;
        position: absolute;
        // z-index: 1;
        // background-color: var(--color-primary);
        color: inherit;
        top: .5em;
        left: 0;
        pointer-events: none;
        // text-transform: uppercase;
        position: absolute;
        transform: translate(0, .59em) scale(1);
        transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transform-origin: top left;
        // font-size: inherit;
        @include rmin(1024){
            font-size: 80%;
        }
        @include r(1023){
            top: 0;
            transform: none;
        }
    }
    [data-validate] ~ .form__label{
        &::after{
            content: '*';
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
            top: -0.5em;
            color: $field_border-color--error;
            display: inline;
        }
    }
    // .input-error ~ .form__label{
    //     color: $field_border-color--error;
    // }
    .form__field_active{
        .form__label{
			padding-inline: .2em;
            pointer-events: none;
            transform: translate(0, -.47em) scale(0.65);
            top: 0;
            background-color: var(--form-label-bg,#fff);
            // font-size:  var(--smSize);
        }
        .form__input{
            border-bottom-color: var(--color-text);
        }
    }
}

.form_js-validate{
    padding: 0 5px;
}
.form{
    display: grid;
    grid-template-columns: minmax(20px,140px) 1fr minmax(20px,140px);
    // grid-template-rows: minmax(100px,26vh) 1fr minmax(100px,auto);
    // @include r(1023){
    //     grid-template-rows: minmax(100px,26vh) 1fr minmax(100px,auto);
    // }
    align-items: center;
    &__wrapper{
        grid-column: 2/3;
        padding-top: 100px;
        padding-bottom: 100px;
        // grid-row: 2/3;
        flex: 1;
        min-width: mIn(calc(100vw - 60px), 400px);

    }
    &__title,
    h2,
    .h2{
        margin-bottom: 0.6em;
    }
    .color-red{
        color: $field_error-bg;
    }
}


.info-tool{
    position: fixed;
    bottom: 8vmin;
    left: var(--formOffset);
    width: var(--formWidth);
    display: flex;
    align-items: center;
    padding: 1em;
    font-size: 1.2rem;
    opacity: 0;
    transform: translateY(.6em);
    z-index: 999;
    box-shadow: 0px 30px 40px -5px rgba(69, 90, 100, 0.2);
    border-radius: 30px;
    color: var(--color-white);
    justify-content: flex-start;
    min-height: 1px;
    padding: .8em 2.5em .8em 1.5em;
    @include r(1023){
        font-size: 16px;
    }
    p{
        margin: 0;
    }
    &_success{
        background: var(--color-primary);

    }
    &_error{
        background: var(--color-error);
    }
    .close{
        @extend %reset-btn;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
    }
    &.start-animation{
        transition: all $transition-base;
        &.is-animating{
            opacity: 1;
            transform: none;
        }
    }
    .subscribe &{
        --formOffset: 0;
        bottom: auto;
        top: calc(100% + 1em);
    }
}


.form-button{
	@include header-btn($color-white,$color-primary-200);
}



#outerSelect{
	width: 100% !important;
	.jdropdown-header{
		padding: 0.7142857143em 0;
		display: block;
		width: 100%;
		background-color: rgba(0,0,0,0);
		border-radius: 0;
		border: none;
		border-bottom: 1px solid #d6d6d6;
		line-height: 1.2;
		transition: all .33s cubic-bezier(.23,1,.32,1);
	}
	.jdropdown-container{
		max-width: 100%;
	}
	.jdropdown-content{
		scrollbar-width: thin;
		scrollbar-color: var(--color-primary) #e2e2e2;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: #e2e2e2;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--color-primary);
			border-radius: 20px;
		}
	}

	&.jdropdown-focus .jdropdown-header{
		@include field_focus();
	}
	&.input-error .jdropdown-header{
		border-color: $field_border-color--error;
	}
}
