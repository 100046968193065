:root{
	--ml-gap: calc(4.28 * var(--fz));
	--ml-inner-gap: calc(3.57 * var(--fz));
	--gh: calc(50vh - var(--ml-gap)*3/2 - var(--ml-inner-gap)/2 - 24px);
}

.main-page{
	padding: var(--ml-gap);
	.container{
		padding-left: 0;
		padding-right: 0;
		max-width: 100%
	}
}


.main-list{
	@extend %reset-list;
	margin-top: var(--ml-gap);
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: var(--ml-inner-gap);
	@include r(767){
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
	}
	.ml-item{
		position: relative;
		border-radius: 20px;
		overflow: hidden;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 2.3rem;
		color: $color-white;
		font-weight: 700;
		@include r(767){
			border-radius: 10px;
			opacity: .5;
			pointer-events: none;
		}
		&:first-child{
			@include rmin(768){
				grid-row: 1 / span 2;
			}
		}
		&:not(:first-child){
			min-height: mAx(var(--gh), 270px);
			&::before{
				content: '';
				display: block;
				width: 100%;
				// padding-bottom: calc(calc(315/635) * 100%);
				padding-bottom: mAx(var(--gh), 270px);
			}
		}
		&__bg{
			@extend %overlay;
			figure{
				margin: 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			&::after{
				content: '';
				@extend %overlay;
				background-image: linear-gradient(180deg, rgba(69, 90, 100, 0) 38.57%, #455A64 100%);
				background-size: 100% 130%;
				background-position: 50% 0;
				background-repeat: no-repeat;
				opacity: 0.4;
				transition: background-position .23s;
			}
		}
		&:hover{
			.ml-item__bg{
				&::after{
					background-position: 50% -40%;
				}
			}
		}
		&__title{
			text-transform: uppercase;
			position: absolute;
			bottom: 1em;
			left: 1em;
			line-height: 1;
			font-family: $title-font;
		}
	}
}
