%button{
    @extend %reset-btn;
    padding: .5em 1em .55em 1em;
    display: inline-flex;
    flex: 0 0 auto;
    max-width: 100%;
    // font-size: var(--smSize);
    font-size: inherit;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    min-height: em(50);
    min-width: em(180);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
}


@property --clr1 {
    syntax: '<color>';
    initial-value: #F1482B;
    inherits: false;
}
@property --clr2 {
    syntax: '<color>';
    initial-value: #FF9C74;
    inherits: false;
}
@property --angle {
    syntax: '<angle>';
    initial-value: 96.2deg;
    inherits: false;
}
@property --alpha {
    syntax: '<number>';
    initial-value: 0.1;
    inherits: false;
}



.btn{
    @extend %button;
    --clr1: #F1482B;
    --clr2: #FF9C74;
    --angle: 96.2deg;
    &_empty{
        border: 2px solid transparent;
        background:
        linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
        linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%) border-box;
        transition: --clr1 $transition-base, --clr2 $transition-base;

        span{
            background: linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: --clr1 $transition-base , --clr2 $transition-base ;
            &.btn__icon{
                width: 1.5em;
                height: 1.5em;
                display: block;
                .svg-icon{
                    max-width: 100%;
                    max-height: 100%;
                }
                & ~ .btn__text{
                    margin-left: 0.5em;
                }
            }
            &.btn__text{
                text-transform: none;
                margin: 0 auto;
            }
        }
        &:hover{
            animation: emBtn 15s ease infinite both;
            span{
                animation: emBtn 20s ease infinite both;
            }
        }
        &-disabled{
            filter: grayscale(1);
            opacity: .8;
            pointer-events: none;
        }
    }
    &_primary{
        --clr1: #2DB94C;
        --clr2: #44DE6F;
        --angle: 96.2deg;
        background: linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%);
        span{
            color: var(--color-bg);
        }
        &:hover{
            animation: mainBtn 15s ease infinite both;
        }
    }
    &_primary-empty{
        --clr1: #2DB94C;
        --clr2: #44DE6F;
        border: 2px solid transparent;
        background:
            linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
            linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%) border-box;
        transition: --clr1 $transition-base, --clr2 $transition-base;

        span{
            --clr1: #2DB94C;
            --clr2: #44DE6F;
            background: linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: --clr1 $transition-base , --clr2 $transition-base ;
            &.btn__text{
                text-transform: none;
                margin: 0 auto;
            }
        }
        &:hover{
            animation: emBtn2 15s ease infinite both;
            span{
                animation: emBtn2 20s ease infinite both;
            }
        }
        &-disabled{
            filter: grayscale(1);
            opacity: .8;
            pointer-events: none;
        }
    }
    &_secondary{
        --clr1: #F1482B;
        --clr2: #FF9C74;
        --angle: 96.2deg;
        background: linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%);
        span{
            color: var(--color-bg);
        }
        &:hover{
            animation: emBtn 15s ease infinite both;
        }
    }
    &_disabled{
        background-color: var( --color-border_dark);
        span{
            color: var(--color-bg);
        }
        &:hover{
            cursor: not-allowed;
        }
    }
    &_promo-gradient{
        --alpha: 0.1;
        --clr1: hsla(9, 88%, 56%, var(--alpha));
        --clr2: hsla(17, 100%, 73%, var(--alpha));
        --angle: 96.2deg;
        background: linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%);
        justify-content: space-between;
        .dark-theme &{
            --alpha: .5;
        }
        span{
            --alpha: 1;
            background: linear-gradient(var(--angle), var(--clr1) 7.85%, var(--clr2) 103.93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: --clr1 $transition-base , --clr2 $transition-base ;
            &.btn__icon{
                width: 1.5em;
                height: 1.5em;
                display: block;
                .svg-icon{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &.btn__text{
                text-transform: none;
                margin: 0 auto;
            }

        }
        &:hover{
            animation: promoBtn 15s ease infinite both;
            span{
                animation: emBtn 15s ease 1s infinite both;
            }
        }
        &-disabled{
            filter: grayscale(1);
            opacity: .8;
            pointer-events: none;
        }
    }
    &.btn_smallerText{
        min-height: rem(40,10);
        font-size: 72%;
        min-width: 18rem;
    }
}



@keyframes emBtn {
    0%{
        --angle: 96.2deg;
        --clr1: #F1482B;
        --clr2: #FF9C74;
    }
    50%{
        --clr1: var(--color-bg);
        --clr2: #F1482B;

    }
    100%{
        --angle: 800deg;
        --clr1: #F1482B;
        --clr2: #FF9C74;
    }
}
@keyframes emBtn2 {
    0%{
        --angle: 96.2deg;
        --clr1: #2DB94C;
        --clr2: #44DE6F;
    }
    50%{
        --clr1: var(--color-bg);
        ---clr2: #2DB94C;

    }
    100%{
        --angle: 800deg;
        --clr1: #44DE6F;
        --clr2: #2DB94C;
    }
}

@keyframes mainBtn {
    0%{
        --angle: 96.2deg;
        --clr1: #2DB94C;
        --clr2: #44DE6F;
    }
    50%{
        --clr1: var(--color-bg);
        --clr2: #2DB94C;

    }
    100%{
        --angle: 276deg;
        --clr1: #44DE6F;
        --clr2: #2DB94C;
    }
}

@keyframes promoBtn {
    0%{
        --angle: 96.2deg;
        --clr1: hsla(9, 88%, 56%, var(--alpha));
        --clr2: hsla(17, 100%, 73%, var(--alpha));
    }
    50%{
        --clr1: var(--color-bg);
        --clr2: hsla(9, 88%, 56%, var(--alpha));

    }
    100%{
        --angle: 276deg;
        --clr1: hsla(17, 100%, 73%, var(--alpha));
        --clr2: hsla(9, 88%, 56%, var(--alpha));
    }
}