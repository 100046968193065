// Typography
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-style: normal;
	line-height: 1.25;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h1,
.h1 {
	font-size: var(--h1Size);
	margin-bottom: .2em;
	font-weight: 400;
}

h2,
.h2 {
	font-size: var(--h2Size);
	margin-bottom: .55em;
	font-weight: 400;
}

h3,
.h3 {
	font-size: var(--h3Size);
	margin-bottom: .55em;
	font-weight: 700;
}

h4,
.h4 {
	font-size: var(--h4Size);
	margin-bottom: .4em;
	font-weight: 500;
}

h5,
.h5 {
	font-size: var(--h5Size);
	margin-bottom: .35em;
	font-weight: 500;
}

h6,
.h6 {
	font-size: var(--h6Size);
	margin-bottom: .5em;
	font-weight: 600;
}
